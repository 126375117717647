import apiClient from '@/services/axios'

export const getFulfillmentList = ({ url, params }) => {
  return apiClient({
    method: 'get',
    url: `/${url}/list`,
    params,
  })
}

export const getFulfillmentDetail = ({ url, internal = false, fulfillment_id, params = undefined }) => {
  const path = internal ? '/internal' : ''
  return apiClient({
    method: 'get',
    url: `/${url + path}/detail/${fulfillment_id}`,
    params,
  })
}

export const getFulfillmentDetailByOrder = ({ signal, business_id, order_id }) => {
  return apiClient({
    signal,
    method: 'get',
    url: `/fulfillment/order/${order_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getCatalogFulfillment = (params) => {
  return apiClient({
    method: 'get',
    url: '/fulfillment-cmd/internal/catalog',
    params,
  })
}

export const splitShipment = ({ business_id, url, data }) => {
  return apiClient({
    method: 'post',
    url: `/${url}-cmd/split`,
    headers: {
      'Business-id': business_id,
    },
    data,
    params: { business_id },
  })
}

export const submitToWMS = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: '/fulfillment-cmd/wms',
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const changeQty = ({ url, fulfillment_id, data }) => {
  return apiClient({
    method: 'put',
    url: `/${url}-cmd/${fulfillment_id}/items`,
    data,
  })
}

export const updateFulfillmentStatus = ({ url, type, data }) => {
  return apiClient({
    method: 'put',
    url: `/${url}-cmd/state/${type}`,
    data,
  })
}

export const fulfillmentCancelItem = ({ url, fulfillment_id, data }) => {
  return apiClient({
    method: 'delete',
    url: `/${url}-cmd/${fulfillment_id}/remove-item`,
    data,
  })
}