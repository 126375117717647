<template>
  <a-modal :visible="visible" :closable="false">
    <h6 class="mt-2 text-center">
      Bulk Print Shipping Label
    </h6>
    <template #footer>
      <a-button key="back" :loading="loading" class="mr-2" @click="closeModal()">
        Batalkan
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="submitModal()">
        Print
      </a-button>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: 'ModalBulkPrintShippingLabel',
  props: {
    visible: Boolean,
    back: Function,
    loading: Boolean,
  },
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('handleShowModal', false)
    },
    submitModal() {
      this.$emit('handleSubmitModal', true)
    },
  },
}
</script>

<style lang="scss" module>
</style>