import apiClient from '@/services/axios'

export const getShippingMethodMaster = ({ business_id }) => {
  return apiClient({
    method: 'get',
    url: '/shippingv2/api/v1/shipping-methods',
    params: {
      business_id: business_id,
    },
  })
}

export const getShippingCourier = () => {
  return apiClient({
    method: 'get',
    url: '/shippingv2/api/v1/courier/list',
  })
}

export const getShippingService = ({ courier_id }) => {
  return apiClient({
    method: 'get',
    url: '/shippingv2/api/v1/shipping-methods-courier',
    params: {
      courier_id,
    },
  })
}

export const getShippingUnit = ({ product_id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `/shippingv2/api/v1/productShippingUnit/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params: { business_id },
  })
}

export const getShippingOrder = ({ signal, params }) => {
  return apiClient({
    signal,
    method: 'get',
    url: '/shippingv2/api/v1/shipping-orders',
    params,
  })
}

export const getShippingOrderFromDashboard = ({ signal, order_id }) => {
  return apiClient({
    signal,
    method: 'get',
    url: `bi/dashboard2/internal/order/detail/${order_id}`,
  })
}

export const shippingDelivered = (data) => {
  return apiClient({
    method: 'post',
    url: '/shipping/cmd/delivered',
    data,
  })
}

export const saveProductShipping = (data) => {
  return apiClient({
    method: 'put',
    url: `/shipping/cmd/product-shipping-unit/${data.product_id}`,
    data,
    params: {
      business_id: data.business_id,
    },
  })
}